import { Controller } from '@hotwired/stimulus';

export default (class extends Controller {
  static values = { mobileMenuActive: Boolean, scrolled: Boolean }

  connect() {
    this.toggleScrolled()
  }

  close(event) {
    if (this.element.contains(event.target)) return

    this.mobileMenuActiveValue = false
  }

  toggleMobileMenu() {
    this.mobileMenuActiveValue = !this.mobileMenuActiveValue
  }

  toggleScrolled() {
    this.scrolledValue = window.scrollY > 0
  }
});
