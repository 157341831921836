import { Controller } from '@hotwired/stimulus'
import noUiSlider from 'nouislider'
import numeral from 'numeral'

export default class extends Controller {
  static targets = [
    'expensesSlider', 'expensesAmount', 'expensesLabel', 'bookkeepingPrice', 'bookkeepingTaxPrice', 'rangeLabel'
  ]

  static values = {
    pricing: { type: Object, default: {} },
    tiers: { type: Array, default: [] }
  }

  connect() {
    this.slider = noUiSlider.create(this.expensesSliderTarget, {
      behaviour: 'tap-drag',
      connect: [true, false],
      format: {
        to: (value) => parseInt(value),
        from: (value) => parseInt(value)
      },
      range: this.generateSliderRange(),
      start: this.tiersValue[0]
    })

    this.generateSliderNotches()

    // Run upon release of slider handle
    this.slider.on('start', (values, handle) => {
      document.querySelector('.noUi-touch-area').classList.add('hide-tooltip')
    })

    this.slider.on('change', (values, handle, positions) => {
      const value = positions[0]
      const closestTier = this.getClosestTier(value)
      this.slider.set(closestTier)
      this.setHandleBg(closestTier)
    })

    // Runs when dragging slider handle
    this.slider.on('update', (values, handle, positions) => {
      const value = positions[0]
      const closestTier = this.getClosestTier(value)
      this.setHandleBg(closestTier)

      // Color/decolor the notches
      document.querySelectorAll('.slider-notch').forEach(notch => {
        if (value >= parseInt(notch.dataset.value)) {
          notch.classList.add('colored')
        } else {
          notch.classList.remove('colored')
        }
      })

      // Set expense label/amount text
      let expenseAmountText = numeral(value).format('$0,0')

      // Value is $100,000+
      if (closestTier === this.tiersValue[this.tiersValue.length - 1]) {
        this.rangeLabelTarget.textContent = 'Over'
        expenseAmountText = numeral(
          this.tiersValue[this.tiersValue.length - 1] - 1
        ).format('$0,0')
      } else {
        this.rangeLabelTarget.textContent = 'Up to'
      }

      this.expensesAmountTarget.textContent = expenseAmountText
      this.setHandleBg(closestTier)

      this.bookkeepingPriceTarget.textContent =
        numeral(this.pricingValue.bookkeeping[closestTier]).format('$0,0')

      this.bookkeepingTaxPriceTarget.textContent =
        numeral(this.pricingValue.bookkeeping_tax[closestTier]).format('$0,0')
    })
  }

  generateSliderNotches() {
    const notchList = document.createElement('ul')
    notchList.classList.add('slider-notches')

    this.tiersValue.forEach((tier, index) => {
      const notch = document.createElement('li')
      notch.classList.add('slider-notch')
      notch.dataset.value = tier
      notchList.appendChild(notch)
    })

    document.querySelector('.noUi-base').appendChild(notchList)
  }

  generateSliderRange() {
    const range = {}
    const gap = (100 / (Object.keys(this.tiersValue).length - 1)) * 1.0

    this.tiersValue.forEach((tier, index) => {
      const tierLevel = gap * index
      let tierKey

      if (tierLevel === 0) {
        tierKey = 'min'
      } else if (tierLevel >= 100) {
        tierKey = 'max'
      } else {
        tierKey = `${tierLevel}%`
      }

      range[tierKey] = tier
    })

    return range
  }

  getClosestTier(value) {
    const tiersLength = this.tiersValue.length
    const lastTier = this.tiersValue[tiersLength - 1]
    const penultimateTier = this.tiersValue[tiersLength - 2]
    const lastTiersDifference = lastTier - penultimateTier

    // Snap to last tier/notch if slider position value is >= 100,000.5 since last tier is 100,001+
    if (value >= penultimateTier + lastTiersDifference / 2) {
      return this.tiersValue[this.tiersValue.length - 1]
    }

    return this.tiersValue.reduce((prev, curr) => {
      return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev)
    })
  }

  setHandleBg(closestTier, value = null) {
    const tierIndex = this.tiersValue.indexOf(closestTier)
    const sliderHandle = document.querySelector('.noUi-handle')
    let newBgPos

    if (tierIndex >= 4) {
      newBgPos = 'right'
    } else if (tierIndex >= 2 && tierIndex < 4) {
      newBgPos = 'center'
    } else {
      newBgPos = 'left'
    }

    sliderHandle.dataset.bgPosition = newBgPos
  }
}
